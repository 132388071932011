.skills-me-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.skills-me-parent{
  max-width: 1000px;
  width: 70%;
}

/* .btn {
  display: block;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
} */

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(216, 81, 18, 0.733) 0px 3px 3px 0px;
  border: 0;
  background-color: #f7f5f4d1;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.section {
  margin-top: 5em;
}

.skills__list {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}
