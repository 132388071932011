h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
  color: #f77801ed;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-me-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}

.contact-me-parent{
  max-width: 1000px;
  width: 70%;
}

.contact__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.contact {
  padding: 5em;
  margin: 20px ;
  text-align: center;
  /* box-shadow: var(--shadow); */
  box-shadow: rgba(216, 81, 18, 0.733) 0px 3px 3px 0px;
  transition: transform 0.2s linear;
  background-color: #f7f5f4d1;
  border-radius: 20px;
  cursor: pointer;
}

.contact:hover {
  transform: translateY(-7px);
}

.contact__description {
  margin-top: 1em;
  color: #933d00fe;
}


.contact .link--icon {
  margin-left: 0.5em;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
  font-size: 1.2rem;
}
