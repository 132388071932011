.about-me-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
}
.about-me-parent{
    max-width: 1000px;
    width: 70%;
}
.about-me-card{
    display: flex;
    width: 100%;
    /* box-shadow: 0 0px 20px -2px #1f2235; */
    box-shadow: rgba(216, 81, 18, 0.733) 0px 3px 3px 0px;
    background-color: #f7f5f4d1;
    border-radius: 20px;
}
/* .about-me-profile{
    width: 45%;
    background-image: url("../Images/img/AboutMe/DSC_0459.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
} */

.about-me-details{
    width: 100%;
    text-align: justify;
    padding: 40px;
}
.about-me-description{
    font-size: 15px;
}
.about-me-highlights{
    margin: 50px 0;
   
}
.highlight-heading {
    color: black;
    font-family: "Poppins Bold";
    font-size: 14px;
    margin: 0 0 5px 0;
  }
.highlight{
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 15px;
}
.highlight-blob{
    height: 12px;
    width: 12px;
    margin: 0 12px 0 0;
    background-color: #ff5823;
    border-radius: 50%;
}
.about-me-options .highlighted-btn{
    margin: 0 0 0 30px;
}

@media only screen and(max-width: 1110px){
    .about-me-parent{
        width: 88%;
    }
}
@media only screen and (max-width: 898px){
   .about-me-profile{
       display: none;
   }
   .about-me-details{
       width: 100%;
   }
}
@media only screen and (max-width: 466px){
  .about-me-options{
      display: flex;
      flex-direction: column;
  }
  .about-me-options .highlighted-btn{
      margin: 24px 0 0 0 !important;
  }
  .about-me-options button{
    width: 100%;
  }
}